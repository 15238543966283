$(function(){
	document.getElementById('form_entrance_site').addEventListener('submit', function(evt){
		var http = new XMLHttpRequest(), f = this;
	evt.preventDefault();
	http.open("POST", "contacts.php", true);
	http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
	http.send("messageNN=" + f.messageNN.value + "&number=" + f.number.value + "&date=" + f.date.value + "&year=" + f.year.value + "&two_hours_time_your_birth_hours=" + f.two_hours_time_your_birth_hours.value + "&two_hours_time_your_birth_minutes=" + f.two_hours_time_your_birth_minutes.value + "&twenty_minutes_time_your_birth_hours=" + f.twenty_minutes_time_your_birth_hours.value + "&twenty_minutes_time_your_birth_minutes=" + f.twenty_minutes_time_your_birth_minutes.value);
	http.onreadystatechange = function() {
		if (http.readyState == 4 && http.status == 200) {
		  $(".info_overlay").hide();
		  $(".thank_you_for_registering").show();
		}
	}
		http.onerror = function() {
		alert('Извините, данные не были переданы');
		}
	}, false);
});

$(function(){
	document.getElementById('forgot_password').addEventListener('submit', function(evt){
		var http = new XMLHttpRequest(), f = this;
	evt.preventDefault();
	http.open("POST", "contacts.php", true);
	http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
	http.send("forgot_password=" + f.forgot_password.value + "&contactEE=" + f.contactEE.value);
	http.onreadystatechange = function() {
		if (http.readyState == 4 && http.status == 200) {
		  $(".info_forgot_password .inst").show();
		}
	}
		http.onerror = function() {
		alert('Извините, данные не были переданы');
		}
	}, false);
});

$(function(){
	document.getElementById('form_editing_data').addEventListener('submit', function(evt){
		var http = new XMLHttpRequest(), f = this;
	evt.preventDefault();
	http.open("POST", "contacts.php", true);
	http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
	http.send("form_editing_data=" + f.form_editing_data.value + "&name=" + f.name.value + "&surname=" + f.surname.value + "&choicesex=" + f.choicesex.value 
	+ "&marital_status=" + f.marital_status.value + "&marital_status_woman=" + f.marital_status_woman.value + "&date_birthh=" + f.date_birthh.value + "&month_birth=" + f.month_birth.value 
	+ "&year_birth=" + f.year_birth.value + "&two_hours_time_your_birth_hours=" + f.two_hours_time_your_birth_hours.value + "&two_hours_time_your_birth_minutes=" + f.two_hours_time_your_birth_minutes.value 
	+ "&twenty_minutes_time_your_birth_hours=" + f.twenty_minutes_time_your_birth_hours.value + "&twenty_minutes_time_your_birth_minutes=" + f.twenty_minutes_time_your_birth_minutes.value 
	+ "&city=" + f.city.value + "&education=" + f.education.value + "&languagess=" + f.languagess.value + "&interestss=" + f.interestss.value + "&growthh=" + f.growthh.value + "&bodytype=" + f.bodytype.value 
	+ "&attitudetowardssmoking=" + f.attitudetowardssmoking.value + "&attitudealcohol=" + f.attitudealcohol.value + "&mainthinglife=" + f.mainthinglife.value + "&importantpeople=" + f.importantpeople.value 
	+ "&aboutmyself=" + f.aboutmyself.value + "&lookingmyselff=" + f.lookingmyselff.value);
	http.onreadystatechange = function() {
		if (http.readyState == 4 && http.status == 200) {
		alert(http.responseText + 'Данные изменены');    
		  f.messageFF.removeAttribute('value');
		  f.messageFF.value='';
		}
	}
		http.onerror = function() {
		alert('Извините, данные не были переданы');
		}
	}, false);
});

$(function(){ 
	var buttons = $(".buttons");
	fimenu();
	
    function fimenu(e){
		var scrolltop = $(this).scrollTop();
		
		if(scrolltop > 270){
			buttons.slideDown();
			
		}else{
			buttons.slideUp();
		}
	}
	
	$(document).on("scroll", fimenu);
	
	var burgerMenu = $(".navbar-header .menu-trigger");
	var content = $(".burger-menu");
	
	burgerMenu.on("click", function(e){
		e.preventDefault();
		burgerMenu.toggleClass("active");
		content.slideToggle();
	});
	
	var region = $(".info_header .region .rgn");
	var dropdown = $(".info_header .region .dropdown");
	
	region.on("click", function(e){
		e.preventDefault();
		dropdown.slideToggle();
	});
	
	$(".sidebar_navigation_questionnaire .info_ank").on("click", function(e){
		e.preventDefault();
		$(".sidebar_navigation_questionnaire .profile .settings").slideToggle();
	});
	
	$(".form_block .i_already_registered .header").on("click", function(e){
		$(".authorization_coupons_site").show();
		$(".info_overlay").hide();
	});
	
	$(".form_block .go_to_next_popup").on("click", function(e){
		$(".form_block .first_text_block").hide();
		$(".form_block .second_text_block").show();
		$(".form_block .first_part_form").hide();
		$(".form_block .second_part_form").show();
		$(".form_block .i_already_registered").hide();
		$(".form_block .continue_without_registration").show();
	});
	
	$(".form_block .continue_without_registration .header").on("click", function(e){
		$(".info_overlay").hide();
	});
	
	$(".thank_you_for_registering .btn").on("click", function(e){
		$(".thank_you_for_registering").hide();
	});
});

$(function(){
    $('.compatibility_search_page .additional_information .fa-ellipsis-h').on('click', function(){
        var answer = $(this).next();
		
        $('.additional_information .info:visible').not(answer).slideUp(400);
        answer.slideToggle(400);
    });
});

$(document).ready(function(){
	$("#choice_sex").on('change', function() {
		if ($(this).find(':selected').val() === '2') {
		$(".editing_page .position_male").removeClass("active_floor_show").addClass("active_floor_hide");
		$(".editing_page .position_female").removeClass("active_floor_hide").addClass("active_floor_show");
	} else {
		$(".editing_page .position_male").removeClass("active_floor_hide").addClass("active_floor_show");
		$(".editing_page .position_female").removeClass("active_floor_show").addClass("active_floor_hide");
	}
	});
});

$(document).ready(function(){
	$("#time_birth").on('change', function() {
		if ($(this).find(':selected').val() === '1') {
		$(".first_block").show();
		$(".second_block").hide();
	} else {
		$(".first_block").hide();
		$(".second_block").show();
	} if ($(this).find(':selected').val() === '0') {
		$(".first_block").hide();
		$(".second_block").hide();
	}
	});
});

$("[data-fancybox]").fancybox({
	speed : 330,
	infobar : true,
	buttons : false,
	protect : true,
});

/*$(document).ready(function(){
	$("#country").autocomplete({
		source: function(request, response){
			$.ajax({
				url: "country.php",
				dataType: "json",
				data: {country:request.term},
				success: function(data){
					response(data);
				}
			});
		},
		minLength: 1
	});
});*/

/*$( function() {
var availableTags = [
  "Абхазия",
  "Австралия",
  "Австрия",
  "Азербайджан",
  "Азорскиеострова",
  "Аландскиеострова",
  "Албания",
  "Алжир",
  "АмериканскоеСамоа",
  "Ангилья",
  "Ангола",
  "Андорра",
  "АнтигуаиБарбуда",
  "Аомынь(Макао)",
  "Аргентина",
  "Армения",
  "Аруба",
  "Афганистан",
  "БагамскиеОстрова",
  "Бангладеш",
  "Барбадос",
  "Бахрейн"
];
$( "#country" ).autocomplete({
  source: availableTags
});
} );*/